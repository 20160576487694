import { computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useMutation, useQuery } from '@tanstack/vue-query'

import { useTeamsStore } from '@/modules/settings/stores/teams'
import MODALS from '@/modules/shared/utils/modals_enum'
import type { ITeams, IcreateTeams, IdeleteTeams } from '@/modules/settings/interfaces/Teams'

const useTeams = () => {
  const storeTeams = useTeamsStore()
  const { teams } = storeToRefs(storeTeams)

  const getUseTeams = useQuery(['teams'], async ():Promise<ITeams[]> => {
    if (teams.value.length === 0) {
      storeTeams.setTeams([
        {
          team: 'Accounting',
          mailboxes: [
            'kevin.malone@dundermifflin.com',
            'angela.martin@dundermifflin.com',
            'meredith.palmer@dundermifflin.com',
            'finance@dundermifflin.com'
          ],
          created: '2023-07-18T12:48:18.838755Z'
        }, {
          team: 'Customer Service',
          mailboxes: [
            'kelly.kapoor@dundermifflin.com'
          ],
          created: '2023-07-18T12:48:18.838755Z'
        }, {
          team: 'HR',
          mailboxes: [
            'hr@dundermifflin.com',
            'toby.flenderson@dundermifflin.com'
          ],
          created: '2023-07-18T12:48:18.838755Z'
        }, {
          team: 'Sales',
          mailboxes: [
            'sales@dundermifflin.com',
            'jim.halpert@dundermifflin.com',
            'dwight.schrute@dundermifflin.com',
            'stanley.hudson@dundermifflin.com',
            'phyllis.vance@dundermifflin.com'
          ],
          created: '2023-07-18T12:48:18.838755Z'
        }
      ])
    }
    return await new Promise<ITeams[]>((resolve) => {
      resolve(teams.value)
    })
  })

  watch(getUseTeams.data, teams => {
    if (teams) storeTeams.setTeams(teams)
  }, { immediate: true })

  const createTeamsMutation = useMutation(async (teamsData: IcreateTeams):Promise<void> => {
    deleteDemoTeams({ data: { teams: [teamsData.team] } })
    const team = {
      team: teamsData.team,
      mailboxes: teamsData.mailboxes,
      created: String(new Date())
    }

    storeTeams.setTeams([
      ...teams.value,
      team
    ])
  })

  const deleteDemoTeams = async (usersData: IdeleteTeams) => {
    usersData.data.teams.forEach((team) => {
      const indexForDelete = teams.value.findIndex(
        (t) => t.team === team
      )
      if (indexForDelete !== -1) {
        const arrayUsers = [...teams.value]
        arrayUsers.splice(indexForDelete, 1)
        storeTeams.setTeams([...arrayUsers])
      }
    })
  }

  const deleteTeamsMutation = useMutation(async (teamsData: IdeleteTeams):Promise<void> => deleteDemoTeams(teamsData))

  return {
    teams,

    // Method
    createTeams: createTeamsMutation,
    deleteTeams: deleteTeamsMutation,

    removeData: (teams:string[]) => {
      const maxLength = 50
      const ts = teams.map(t => {
        return t.length > maxLength ? t.slice(0, maxLength) + '...' : t
      })

      return {
        name: MODALS.removeModal,
        data: {
          type: 'teams',
          title: `Are you sure you want to remove ${teams.length > 1 ? 'these teams' : 'this team'}?`,
          text: `The "${ts.join(', ')}" ${teams.length > 1 ? 'teams' : 'team'} will be permanently deleted, but the mailboxes inside it will still be tracked.`,
          data: teams
        }
      }
    },

    // computed
    // get mailboxes
    isErrorGet: computed(() => getUseTeams.isError.value)
  }
}

export default useTeams
