<script lang="ts" setup>
import { ref } from 'vue'
import { useQueryClient } from '@tanstack/vue-query'
import { useToast } from 'vue-toastification'

import type { Iitems } from '@/modules/shared/interfaces/inputMultiple'

import ButtonsModal from '@/modules/shared/components/ButtonsModal.vue'
import InputMultiple from '@/modules/shared/components/InputMultiple.vue'
import MODALS from '@/modules/shared/utils/modals_enum'
import useModal from '@/modules/shared/composable/useModal'
import useMailboxes from '@/modules/settings/composables/useMailboxes'
import useAlerts from '@/modules/explore/composables/useAlerts'
import SelectMultiple from '@/modules/shared/components/SelectMultiple'
import { domainReg, subdomainReg, emailReg } from '@/modules/shared/utils/reg'
import ToastComponent from '@/modules/shared/components/ToastComponent.vue'
import { CheckCircleIcon } from '@heroicons/vue/solid'
import ToastDemoComponent from '@/modules/shared/components/ToastDemoComponent.vue'

const { createAlerts, editAlerts } = useAlerts()
const toast = useToast()

const { editAlertData, hideModal, activeModal } = useModal()
const queryClient = useQueryClient()
const { mailboxes } = useMailboxes()

const email = ref('')
const emails = ref<Iitems[]>([])

const domain = ref('')
const domains = ref<Iitems[]>([])

const hasDataModal = activeModal.value === MODALS.updateAlerts

const alert = ref(hasDataModal ? editAlertData.value.name : '')

const value = ref(
  hasDataModal
    ? editAlertData.value.mailboxes.map((m: string) => (m))
    : []
)

if (hasDataModal) {
  emails.value = editAlertData.value.notify_emails.map((e) => ({ item: e, status: true, notTraking: false }))
  domains.value = editAlertData.value.domains.map((d) => ({ item: d, status: true }))
}

const options = ref(mailboxes.value.map((m) => (m.email)))

const hours = ref<string>(hasDataModal ? String(editAlertData.value.minutes_gap / 60) : '')
const validNumber = (e: { preventDefault: () => void, charCode:number }) => {
  return (!(e.charCode >= 48 && e.charCode <= 57)) && e.preventDefault()
}

const isDisabled = () => !(alert.value !== '' && value.value.length > 0 && parseInt(hours.value) > 0 && domain.value.length === 0 && domains.value.length > 0 && !isDisabledTextDomain() && email.value.length === 0 && emails.value.length > 0 && !isDisabledTextEmail())
const isDisabledTextDomain = () => domains.value.filter((e) => e.status === false).length > 0
const isDisabledTextEmail = () => emails.value.filter((e) => e.status === false).length > 0

const sendAlert = () => {
  const newAlertsData = {
    name: alert.value,
    minutes_gap: parseInt(hours.value) * 60,
    base: 'unreplied_emails',
    mailboxes: value.value,
    addresses: [],
    domains: domains.value.map(({ item }: { item: string; }) => item),
    notify_emails: emails.value.map(({ item }: { item: string; }) => item)
  }

  if (!hasDataModal) {
    createAlerts.mutate(newAlertsData, {
      onSuccess: () => {
        const query = queryClient.getQueryCache().find(['alerts'], { exact: true })
        query?.fetch()
        hideModal()
        if (!import.meta.env.VITE_DEMO.length) {
          toast.success(ToastComponent, { icon: CheckCircleIcon })
        } else {
          toast.success(ToastDemoComponent, { icon: CheckCircleIcon })
        }
      }
    })
  } else {
    editAlerts.mutate({ alert: newAlertsData, id: editAlertData.value.id }, {
      onSuccess: () => {
        const query = queryClient.getQueryCache().find(['alerts'], { exact: true })
        query?.fetch()
        hideModal()
        if (!import.meta.env.VITE_DEMO.length) {
          toast.success(ToastComponent, { icon: CheckCircleIcon })
        } else {
          toast.success(ToastDemoComponent, { icon: CheckCircleIcon })
        }
      }
    })
  }
}
</script>

<template>
  <p class="text-gray-900 font-semibold leading-8 text-lg p-6 border-b">{{ hasDataModal ? 'Edit alert' : 'Create new alert' }}</p>
  <div class="p-6 border-b overflow-auto">
    <div class="pb-6">
      <label for="alert" class="block mb-1 text-sm font-medium leading-5 text-gray-700">Alert name</label>
      <input
        id="alert"
        type="text"
        name="alert"
        v-model="alert"
        class="block w-full rounded-md border-0 py-2 px-3.5 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-5.25"
        placeholder="Type a name for your alert" />
    </div>
    <div class="pb-6">
      <SelectMultiple
          v-model:values="value"
          :options="options"
          placeholder="Choose the mailboxes that this alert will monitor"
          label="When an email received in"
        />
    </div>
    <div id="domains-component" class="pb-6">
      <label class="block text-sm font-medium leading-5 text-gray-700 mb-1">Sent by any of the following email addresses or domains</label>
        <InputMultiple
          placeholder="Type in or paste a list of addresses or domains"
          v-model:text="domain"
          v-model:items="domains"

          :reg="(e:string) => (!(domainReg.test(e) || subdomainReg.test(e) || emailReg.test(e)))"
          :allowedDomains="[]"
          :onlyExistEmails="[]"
        />
      <span v-show="isDisabledTextDomain()" class="text-red-400 block mt-1 text-left text-xs font-inter font-medium">
        Please check that all domains are correct.
      </span>
    </div>
    <div class="pb-6">
      <label for="hours" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Is unreplied for longer than</label>
      <div class="flex rounded-md">
        <div class="relative flex-grow focus-within:z-10 flex">
          <input
            id="hours"
            class="shadow-sm w-full rounded-l-md border-0 py-2 px-3.5 text-sm leading-5.25 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 placeholder:text-sm focus:ring-2 focus:ring-inset"
            name="hours"
            placeholder="Set the number of hours that an email can remain unreplied"
            type="text"
            v-model="hours"
            @keypress="validNumber" />
            <span class="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 text-gray-500 sm:text-sm bg-gray-50">hours</span>
        </div>
      </div>
    </div>

    <div id="emails-component" class="pb-6">
      <label class="block text-sm font-medium leading-5 text-gray-700 mb-1">Send an alert to</label>
      <InputMultiple
        placeholder="Type in or paste the emails addresses that should receive the alert"
        v-model:text="email"
        v-model:items="emails"

        :reg="(e:string) => (!(emailReg.test(e)))"
        :allowedDomains="[]"
        :onlyExistEmails="[]"
      />
      <span v-show="isDisabledTextEmail()" class="text-red-400 block mt-1 text-left text-xs font-inter font-medium">
        Please check that all email addresses are correct.
      </span>
    </div>
  </div>
  <ButtonsModal
    :type="hasDataModal ? 'Edit alert' : 'Create alert'"
    :isDisabled="isDisabled()"
    @submit="sendAlert" />
</template>

<style scope></style>
