import { computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import type { IDashboard } from '@/modules/dashboard/interfaces/dashboard'
import { useDashboardStore } from '@/modules/dashboard/stores/dashboard'

const useDashboard = () => {
  const storeDasboard = useDashboardStore()
  const { dashboard } = storeToRefs(storeDasboard)

  const getUseDashboard = useQuery(['dashboard'], async ():Promise<IDashboard[]> => {
    // return []
    return [{
      name: 'Email Meter',
      created: '2023-06-02',
      dashboard_url: 'https://lookerstudio.google.com/embed/reporting/79b1e8e7-6881-4cb9-8ba4-49bbf29460d7/',
      dashboard_embedded_url: 'https://lookerstudio.google.com/embed/reporting/79b1e8e7-6881-4cb9-8ba4-49bbf29460d7/'
    }]
  }, { staleTime: 10 * (60 * 1000) })
  watch(getUseDashboard.data, dashboardNew => {
    if (dashboardNew) storeDasboard.setDashboard(dashboardNew)
  }, { immediate: true })

  const href = (e:{ preventDefault: ()=> void }) => {
    if (dashboard.value.length) window.open(dashboard.value[0].dashboard_url, '_blank')
    else e.preventDefault()
  }

  return {
    // properties
    dashboard,

    // Method
    href,
    // computed
    getData: computed(() => getUseDashboard.data),
    isLoadingGet: computed(() => getUseDashboard.isLoading.value),
    isErrorGet: computed(() => getUseDashboard.isError.value)
  }
}

export default useDashboard
