<script setup lang="ts">
import { computed } from 'vue'

import type { IMailboxes } from '@/modules/settings/interfaces/Mailboxes'
import {
  headMailboxes,
  bodyMailboxes,
  bodySearchNotFound,
  bodyNotData,
  BodyNotConnection
} from '@/modules/settings/components/table'
import ButtonRS from '@/modules/shared/components/ButtonRemoveSelected.vue'
import useTable from '@/modules/settings/composables/useTable'
import useMailboxes from '@/modules/settings/composables/useMailboxes'
import useModal from '@/modules/shared/composable/useModal'
import MODALS from '@/modules/shared/utils/modals_enum'

const { search, selectedItems, clearSearch, filterData } = useTable()
const { mailboxes: data, isErrorGet, removeData } = useMailboxes()
const { showModalMailboxBusinessHours, removeModal } = useModal()

const mailboxes = computed(() => filterData(data.value, ['email', 'name']))

const click = () => {
  showModalMailboxBusinessHours({
    name: MODALS.updateBusiness,
    data: {
      mailbox: mailboxes.value.filter((mailbox:IMailboxes) => { return selectedItems.value.includes(mailbox.email) })
    }
  })
}
</script>

<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="mt-5 flow-root">
      <div class="sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full pb-4 align-middle">
          <div class="relative">
            <div v-if="selectedItems.length > 0"
                 class="absolute top-0.5 left-14 flex md:h-[68px] xl:h-12  items-center space-x-3 sm:left-12">
              <ButtonRS text="Edit business hours" @click="click" />

              <ButtonRS text="Remove selected" @click="removeModal(removeData(selectedItems))" />
            </div>
            <div class="bg-white md:rounded-lg border border-gray-200">
              <table class="tabla min-w-full table-fixed divide-y divide-gray-200">
                <!-- Head -->
                <headMailboxes />

                <!-- Connection issues -->
                <BodyNotConnection
                  v-if="isErrorGet"
                  title="Something went wrong"
                  text="We had some troubles loading this data. Please refresh this page to try again and get in touch if the problem sticks around."
                  textButton="Refresh page"
                />

                <!-- Data -->
                <bodyMailboxes
                  v-else-if="data.length > 0 && mailboxes.length > 0"
                  :mailboxes="mailboxes"
                />

                <!-- Search not found -->
                <bodySearchNotFound
                  v-else-if="data.length > 0 && mailboxes.length === 0"
                  title="No mailboxes found"
                  :text='`Your search "${search}" didn’t match any mailbox. <br /> Please try again or add a new mailbox.`'
                  textButton="Add a mailbox"
                  :modal-name="MODALS.addMailboxes"
                  @clearSearch="clearSearch"
                />

                <!-- No data saved -->
                <bodyNotData
                  v-else-if="data.length === 0"
                  title="Start by adding mailboxes"
                  text="Once you’ve added mailboxes, you can filter for them in your dashboard"
                  textButton="Add a mailbox"
                  :modal-name="MODALS.addMailboxes"
                />
              </table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
