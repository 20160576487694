/* eslint-disable no-constant-condition */
import { storeToRefs } from 'pinia'
import { useMutation, useQuery } from '@tanstack/vue-query'
import clientsApi from '@/api/clients-api'

import type { Iitems } from '@/modules/shared/interfaces/inputMultiple'
import type { IMetricAdjustments, IMetricAdjustmentsFront } from '@/modules/settings/interfaces/MetricAdjustments'
import { useMetricAdjustmentsStore } from '@/modules/settings/stores/metricAdjustments'
import { METRICADJUSTMENTS } from '@/api/url'
import { excludedFiltersFront, internalDomainsFront, excludedFiltersBack } from '@/modules/settings/utils/helpers/metricAdjustments'

const useMetricAdjustments = () => {
  const storeMetricAdjustments = useMetricAdjustmentsStore()
  const { metricAdjustments } = storeToRefs(storeMetricAdjustments)
  const { excludeEmails, internalDomains, whitelisted } = storeToRefs(storeMetricAdjustments)

  const getMetricAdjustmentsMutation = useQuery(['excludeEmails'], async ():Promise<IMetricAdjustments> => {
    if (!import.meta.env.VITE_DEMO.length) {
      const { data } = await clientsApi.get(`${METRICADJUSTMENTS}`)

      storeMetricAdjustments.setExcludeEmails(excludedFiltersFront(data.filtering_settings.excluded_filters))
      storeMetricAdjustments.setInternalDomains(internalDomainsFront(data.filtering_settings.internal_domains))
      storeMetricAdjustments.setWhitelisted([...data.client_domains.default_domains])

      return data
    } else {
      const data:IMetricAdjustments = {
        client_domains: { default_domains: [] },
        filtering_settings: {
          excluded_filters: {
            labels: { is: [], contains: [] },
            domains: { is: [], contains: [] },
            folders: { is: [], contains: [] },
            subject: { is: [], contains: [] },
            addresses: { is: [], contains: [] },
            categories: { is: [], contains: [] }
          },
          internal_domains: []
        }
      }

      if (!excludeEmails.value.length) {
        data.client_domains.default_domains = ['dundermifflin.com']
        data.filtering_settings.excluded_filters.labels.is = ['Promotions', 'Social', 'Newsletter']
        data.filtering_settings.excluded_filters.subject.contains = ['Invitation:', 'Updated Invitation::', 'Accepted:', 'Cancelled:', 'Out of the Office:']
        data.filtering_settings.excluded_filters.addresses.is = ['notifications@hubspot.com', 'notifications@salesforce.com', 'updates@airtable.com', 'alerts@mail.zapier.com']
        data.filtering_settings.excluded_filters.addresses.contains = ['donotreply', 'no-reply', 'mailer.service']
        data.filtering_settings.internal_domains = ['dunderprint.com', 'mifflintech.com', 'scrantoncraft.com']
      } else {
        data.client_domains.default_domains = whitelisted.value
        data.filtering_settings.excluded_filters = excludedFiltersBack(excludeEmails.value)
        data.filtering_settings.internal_domains = [...internalDomains.value.map(id => id.item)]
      }

      storeMetricAdjustments.setExcludeEmails(excludedFiltersFront(data.filtering_settings.excluded_filters))
      storeMetricAdjustments.setInternalDomains(internalDomainsFront(data.filtering_settings.internal_domains))
      storeMetricAdjustments.setWhitelisted([...data.client_domains.default_domains])

      return data
    }
  })

  const createMetricAdjustmentsMutation = useMutation(async (data: IMetricAdjustmentsFront[]):Promise<void> => {
    if (!import.meta.env.VITE_DEMO.length) {
      await clientsApi.post(`${METRICADJUSTMENTS}`, {
        client_domains: { default_domains: [...internalDomains.value] },
        filtering_settings: {
          excluded_filters: excludedFiltersBack(data),
          internal_domains: [...internalDomains.value.map(id => id.item)]
        }
      })
    } else {
      const data2 = {
        client_domains: { default_domains: [...internalDomains.value] },
        filtering_settings: {
          excluded_filters: excludedFiltersBack(data),
          internal_domains: [...internalDomains.value.map(id => id.item)]
        }
      }
      storeMetricAdjustments.setExcludeEmails(excludedFiltersFront(data2.filtering_settings.excluded_filters))
    }
  })

  const createInternalDomainsMutation = useMutation(async (data: Iitems[]):Promise<void> => {
    if (!import.meta.env.VITE_DEMO.length) {
      await clientsApi.post(`${METRICADJUSTMENTS}`, {
        client_domains: { default_domains: [...internalDomains.value] },
        filtering_settings: {
          excluded_filters: excludedFiltersBack(excludeEmails.value),
          internal_domains: data.map((d) => d.item)
        }
      })
      storeMetricAdjustments.setInternalDomains(data)
    } else {
      storeMetricAdjustments.setInternalDomains(data)
    }
  })

  return {
    // properties
    metricAdjustments,
    excludeEmails,
    internalDomains,
    whitelisted,

    // Method
    getMetricAdjustments: getMetricAdjustmentsMutation,
    createMetricAdjustments: createMetricAdjustmentsMutation,

    createInternalDomains: createInternalDomainsMutation
  }
}

export default useMetricAdjustments
