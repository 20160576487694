import { createRouter, createWebHistory } from 'vue-router'
import settingsRoutes from '@/modules/settings/settings_routes'
import exploreRoutes from '@/modules/explore/explore_routes'

import LoginComponent from '@/modules/shared/pages/LoginPage.vue'
import HomePage from '@/modules/shared/pages/HomePage.vue'
import NotFound from '@/modules/shared/pages/NotFound.vue'
import AccessDenied from '@/modules/shared/pages/AccessDenied.vue'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: HomePage
  },
  {
    path: '/login',
    name: 'login',
    component: LoginComponent,
    props: true
  },
  {
    path: '/access-denied',
    name: 'accessDenied',
    component: AccessDenied
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFound
  }
]

routes.push(...settingsRoutes)
routes.push(...exploreRoutes)

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

export default router
