<script setup lang="ts">
import PageTitleSettings from '@/modules/settings/components/PageTitleSettings.vue'
import UsersTable from '@/modules/settings/components/table/taps/UsersTable.vue'
import useUsers from '@/modules/settings/composables/useUsers'
import useUserInfo from '@/modules/shared/composable/useUserInfo'
import useTable from '@/modules/settings/composables/useTable'
import MODALS from '@/modules/shared/utils/modals_enum'

const { users } = useUsers()

const { userInfo } = useUserInfo()
useTable().resetTable('email')
</script>

<template>

  <PageTitleSettings
    title="Users"
    description="Manage who has access to the dashboard"
    button="Add new users"
    :max="userInfo.maxUsers"
    :data="users.length"
    :modal-name="MODALS.addUser"
  />

  <UsersTable />
</template>

<style scoped></style>
