/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useMutation, useQuery } from '@tanstack/vue-query'

import type { IBhs, IIBhs } from '@/modules/settings/interfaces/BusinessHours'
import { useMailboxesStore } from '@/modules/settings/stores/mailboxes'
import { weekdaysFormat } from '@/modules/settings/utils/parser/bhs'

const useBusinessHours = (mailbox:string[]) => {
  const storeMailboxes = useMailboxesStore()
  const { mailboxes } = storeToRefs(storeMailboxes)
  const enabled = ref(true)

  const getBusinessHoursMutation = useQuery(['businessHours', mailbox[0]], async ():Promise<IBhs> => {
    const m = mailboxes.value.find((m) => m.email === mailbox[0])
    const data = {
      businessHours: m!.bhs.businessHours,
      timezone: m!.bhs.timezone
    }
    storeMailboxes.setMailboxBusinessHours(mailbox[0], data)
    return data
  }, { enabled })

  const createBusinessHoursMutation = useMutation(async (businessHours: IIBhs):Promise<void> => {
    const bh = businessHours.business_hours.filter((businessHour) => businessHour.start_time === '00:00:00' && businessHour.end_time === '23:59:59')

    if (bh.length === 7) {
      businessHours.mailboxes?.forEach(async (mailbox:string) => {
        const m = mailboxes.value.find((m) => m.email === mailbox)
        m!.bhsStatus = 'Ignored'

        storeMailboxes.setMailboxBusinessHours(mailbox, weekdaysFormat({
          business_hours: [],
          timezone: 'UTC'
        }))
      })
    } else {
      businessHours.mailboxes?.forEach(async (mailbox:string) => {
        storeMailboxes.setMailboxBhsStatus(mailbox)
        storeMailboxes.setMailboxBusinessHours(mailbox, weekdaysFormat({
          business_hours: businessHours.business_hours,
          timezone: businessHours.timezone
        }))
      })
    }
  })

  const deleteBusinessHoursMutation = useMutation(async (mailboxesParams:string[]):Promise<void> => {
    mailboxesParams.forEach(async (mailbox:string) => {
      const m = mailboxes.value.find((m) => m.email === mailbox)
      m!.bhsStatus = 'Company'

      storeMailboxes.setMailboxBusinessHours(mailbox, weekdaysFormat({
        business_hours: [],
        timezone: 'UTC'
      }))
    })
  })

  return {
    // properties
    enabled,

    // Method
    getBusinessHours: getBusinessHoursMutation,
    createBusinessHours: createBusinessHoursMutation,
    deleteBusinessHours: deleteBusinessHoursMutation
  }
}

export default useBusinessHours
