import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import { useAlertsStore } from '@/modules/explore/store/alerts'
import MODALS from '@/modules/shared/utils/modals_enum'
import { useMutation, useQuery } from '@tanstack/vue-query'
import type { ICreateAlerts, IDeleteAlerts } from '@/modules/explore/interfaces/Alerts'

const useAlerts = () => {
  const alertsStore = useAlertsStore()
  const { alerts } = storeToRefs(alertsStore)

  const getUseAlerts = useQuery(['alerts'], async ():Promise<ICreateAlerts[]> => {
    if (alerts.value.length === 0) {
      alertsStore.setAlerts([
        {
          created: '2024-01-02',
          created_by: 'michael.scott@dundermifflin.com',
          id: '0feea2c5-3d98-44d2-b73d-08cb34e6ab50',
          is_active: true,
          last_run: '2024-01-02 17:00',
          name: '24 Hour SLA for VIP Clients',
          addresses: [],
          domains: ['eagleenterprises.org', 'acmefacilities.com', 'midtownassociates.com', 'kingstoncorporation.com', 'fletcherconstruction.com'],
          mailboxes: ['kelly.kapoor@dundermifflin.com'],
          minutes_gap: 1440,
          notify_emails: ['michael.scott@dundermifflin.com']
        }, {
          created: '2024-01-02',
          created_by: 'michael.scott@dundermifflin.com',
          id: '6307268a-ca6b-4e40-b883-f6bf86f39e9f',
          is_active: true,
          last_run: '2024-01-02 17:00',
          name: 'Supplier Invoice Pending',
          domains: ['invoices@paperproviders.com', 'payments@equipmentsuppliers.com', 'accountspayable@cleaningservices.com', 'accounts@techsupply.com'],
          mailboxes: ['finance@dundermifflin.com'],
          minutes_gap: 4320,
          notify_emails: ['michael.scott@dundermifflin.com']
        }, {
          created: '2024-01-02',
          created_by: 'michael.scott@dundermifflin.com',
          id: '1bbf60b9-e1f3-405b-a8bb-401befbfa8a6',
          is_active: true,
          last_run: '2024-01-02 17:00',
          name: 'Unprocessed Client Order',
          domains: ['orders@scrantonbiz.com', 'purchasing@regionalpartners.com', 'sales.orders@corporatecustomers.com', 'buying@majorcorporates.com'],
          mailboxes: ['sales@dundermifflin.com'],
          minutes_gap: 4320,
          notify_emails: ['michael.scott@dundermifflin.com']
        }
      ])
    }
    return await new Promise<ICreateAlerts[]>((resolve) => {
      resolve([...alerts.value])
    })
  })

  watch(getUseAlerts.data, alerts => {
    if (alerts) alertsStore.setAlerts(alerts)
  }, { immediate: true })

  const createAlertsMutation = useMutation(async (alert: ICreateAlerts):Promise<void> => {
    alertsStore.setAlerts([...alerts.value, {
      created: `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`,
      created_by: 'michael.scott@dundermifflin.com',
      id: String(alerts.value.length + 1),
      is_active: true,
      last_run: '2023-12-06 17:00',
      name: alert.name,
      domains: alert.domains,
      mailboxes: alert.mailboxes,
      minutes_gap: alert.minutes_gap,
      notify_emails: alert.notify_emails
    }])
  })

  const editAlertsMutation = useMutation(async (data:{alert: ICreateAlerts | { is_active: boolean }, id:string}):Promise<void> => {
    if ((data.alert as { is_active: boolean }).is_active !== undefined) {
      const a = alerts.value.find((alert) => alert.id === data.id)
      deleteDemoAlert({ data: { alerts: [data.id] } })
      alertsStore.setAlerts([...alerts.value, {
        created: `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`,
        created_by: 'michael.scott@dundermifflin.com',
        id: data.id,
        is_active: (data.alert as { is_active: boolean }).is_active,
        last_run: '2023-12-06 17:00',
        name: (a as ICreateAlerts).name,
        domains: (a as ICreateAlerts).domains,
        mailboxes: (a as ICreateAlerts).mailboxes,
        minutes_gap: (a as ICreateAlerts).minutes_gap,
        notify_emails: (a as ICreateAlerts).notify_emails
      }])
    } else {
      deleteDemoAlert({ data: { alerts: [data.id] } })
      alertsStore.setAlerts([...alerts.value, {
        created: `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`,
        created_by: 'michael.scott@dundermifflin.com',
        id: data.id,
        is_active: true,
        last_run: '2023-12-06 17:00',
        name: (data.alert as ICreateAlerts).name,
        domains: (data.alert as ICreateAlerts).domains,
        mailboxes: (data.alert as ICreateAlerts).mailboxes,
        minutes_gap: (data.alert as ICreateAlerts).minutes_gap,
        notify_emails: (data.alert as ICreateAlerts).notify_emails
      }])
    }
  })

  const deleteDemoAlert = async (as: IDeleteAlerts) => {
    as.data.alerts.forEach((alert) => {
      const indexForDelete = alerts.value.findIndex(
        (mailbox) => mailbox.id === alert
      )
      const arrayAlerts = [...alerts.value]
      arrayAlerts.splice(indexForDelete, 1)
      alertsStore.setAlerts([...arrayAlerts])
    })
  }
  const deleteAlertsMutation = useMutation(async (alerts: IDeleteAlerts):Promise<void> => deleteDemoAlert(alerts))

  return {
    // properties
    alerts,

    // Method
    createAlerts: createAlertsMutation,
    editAlerts: editAlertsMutation,
    deleteAlerts: deleteAlertsMutation,
    removeData: (ids:string[]) => {
      const maxLength = 50

      const ts = ids.map(t => {
        const name = alerts.value[alerts.value.findIndex((a) => a.id === t)].name
        return name.length > maxLength ? `"${name.slice(0, maxLength)}..."` : `"${name}"`
      })

      return {
        name: MODALS.removeModal,
        data: {
          type: 'alerts',
          title: `Are you sure you want to remove ${ids.length > 1 ? 'these alerts' : 'this alert'}?`,
          text: `Your alert ${ts.join(', ')} will be permanently deleted. You can also pause alerts if you plan on using them again.`,
          data: ids
        }
      }
    }
  }
}

export default useAlerts
