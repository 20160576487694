<script lang="ts" setup>
import { ref } from 'vue'
import { useQueryClient } from '@tanstack/vue-query'
import { useToast } from 'vue-toastification'

import type { IModalData } from '@/modules/shared/interfaces/Modal'
import { TrashIcon } from '@heroicons/vue/outline'
import ButtonsModal from '@/modules/shared/components/ButtonsModal.vue'
import useModal from '@/modules/shared/composable/useModal'
import useContactGroups from '@/modules/settings/composables/useContactGroups'
import ToastComponent from '@/modules/shared/components/ToastComponent.vue'
import { CheckCircleIcon } from '@heroicons/vue/solid'
import ToastDemoComponent from '@/modules/shared/components/ToastDemoComponent.vue'

const queryClient = useQueryClient()
const toast = useToast()
const { modalData, hideModal } = useModal()
const { removeContact, getContacts } = useContactGroups()

const mailboxes = ref((modalData.value as IModalData).mailboxes ?? [])
const group = ref((modalData.value as IModalData).name ?? '')

const isDisabled = () => { return false }
const confirmRemoveContacts = () => {
  removeContact.mutate({ name: (modalData.value as IModalData).name, mailboxes: (modalData.value as IModalData).mailboxes as string[] }, {
    onSuccess: () => {
      getContacts.mutate((modalData.value as IModalData).name, {
        onSuccess: () => {
          const query = queryClient.getQueryCache().find(['contactGroups'], { exact: true })
          query?.fetch()
          hideModal()
          if (!import.meta.env.VITE_DEMO.length) {
            toast.success({
              component: ToastComponent,
              props: {
                title: `${mailboxes.value.length === 1 ? 'Contact' : `${mailboxes.value.length} Contacts`} removed`,
                messenges: 'This change will be reflected in your dashboard tomorrow at 00:01 local time'
              },
              options: { icon: CheckCircleIcon }
            }, {
              timeout: 10000
            })
          } else {
            toast.success(ToastDemoComponent, { icon: CheckCircleIcon })
          }
        }
      })
    }
  })
}
</script>

<template>
  <div class="p-6 pb-0 overflow-auto">
    <div class="bg-red-100 w-12 h-12 flex items-center justify-center rounded-full ">
      <TrashIcon class="w-6 h-6 text-red-500" />
    </div>

    <template v-if="mailboxes.length === 1">
      <p class="text-gray-900 font-medium leading-6 text-lg mt-5">Remove contact</p>
      <p class="text-sm leading-5 font-normal text-gray-500 pt-2">Are you sure you want to remove this contact from the <span class="font-medium">{{ group }} </span> group? This action cannot be undone.</p>
    </template>

    <template v-else>
      <p class="text-gray-900 font-medium leading-6 text-lg mt-5">Remove contacts</p>
      <p class="text-sm leading-5 font-normal text-gray-500 pt-2">Are you sure you want to remove <span class="font-medium">{{ mailboxes.length }} contacts </span>from the <span class="font-medium">{{ group }} </span> group? This action cannot be undone.</p>
    </template>
  </div>
  <ButtonsModal
    type="Remove"
    color="red"
    :isDisabled="isDisabled()"
    @submit="confirmRemoveContacts" />
</template>

<style scope></style>
