<script setup lang="ts">
import { computed } from 'vue'

import {
  bodyUsers,
  headUsers,
  bodySearchNotFound,
  BodyNotConnection
} from '@/modules/settings/components/table'
import ButtonRS from '@/modules/shared/components/ButtonRemoveSelected.vue'
import useTable from '@/modules/settings/composables/useTable'
import useUsers from '@/modules/settings/composables/useUsers'
import useModal from '@/modules/shared/composable/useModal'
import MODALS from '@/modules/shared/utils/modals_enum'

const { search, selectedItems, filterData, clearSearch } = useTable()

const { users: data, isErrorGet, removeData } = useUsers()
const { removeModal } = useModal()

const users = computed(() => filterData(data.value, ['email', 'name']))
</script>

<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="mt-5 flow-root">
      <div class="sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full pb-4 align-middle">
          <div class="relative">
            <div v-if="selectedItems.length > 0"
                 class="absolute top-0.5 left-14 flex h-12 items-center space-x-3 sm:left-12">
              <ButtonRS text="Remove selected" @click="removeModal(removeData(selectedItems))" />
            </div>
            <div class="bg-white md:rounded-lg border border-gray-200">
              <table class="tabla min-w-full table-fixed divide-y divide-gray-200">
                <!-- Head -->
                <headUsers />

                <!-- Connection issues -->
                <BodyNotConnection
                  v-if="isErrorGet"
                  title="Something went wrong"
                  text="We had some troubles loading this data. Please refresh this page to try again and get in touch if the problem sticks around."
                  textButton="Refresh page"
                />

                <!-- Data -->
                <bodyUsers
                  v-else-if="users.length > 0"
                  :users="users"
                />

                <!-- Search not found -->
                <bodySearchNotFound
                  v-else-if="users.length === 0"
                  title="No users found"
                  :text='`Your search "${search}" didn’t match any users. <br /> Please try again or add a new users.`'
                  textButton="Add a new user"
                  :modal-name="MODALS.addUser"
                  @clearSearch="clearSearch"
                />
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
