import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { ContactGroups, ContactGroups2, Contacts } from '@/modules/settings/interfaces/ContactGroups'

export const useContactGroupsStore = defineStore('contactGroups', () => {
  const contactGroups = ref<ContactGroups[]>([])
  const contactGroups2 = ref<ContactGroups2>()
  const contacts = ref<Contacts[]>([])

  return {
    // properties
    contactGroups,
    contactGroups2,
    contacts,
    // Actions
    setContactGroups (contactGroupsValue: ContactGroups[]) {
      contactGroups.value = contactGroupsValue
    },
    setContactGroups2 (contactGroupsValue: ContactGroups2) {
      contactGroups2.value = contactGroupsValue
    },
    setContact (newContacts: Contacts[]) {
      contacts.value = newContacts
    }
  }
})
