<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useQueryClient } from '@tanstack/vue-query'
import { useToast } from 'vue-toastification'

import type { Iitems } from '@/modules/shared/interfaces/inputMultiple'
import { domainReg, subdomainReg, emailReg } from '@/modules/shared/utils/reg'
import InputMultiple from '@/modules/shared/components/InputMultiple.vue'
import useModal from '@/modules/shared/composable/useModal'
import ButtonsModal from '@/modules/shared/components/ButtonsModal.vue'
import useContactGroups from '@/modules/settings/composables/useContactGroups'
import ToastComponent from '@/modules/shared/components/ToastComponent.vue'
import { CheckCircleIcon } from '@heroicons/vue/solid'
import ToastDemoComponent from '@/modules/shared/components/ToastDemoComponent.vue'

const queryClient = useQueryClient()
const toast = useToast()
const { hideModal } = useModal()
const { AddContactGroup, contactGroups } = useContactGroups()

const name = ref('')
const item = ref('')
const items = ref<Iitems[]>([])
const groups = contactGroups.value

const checkName = computed(() => !!groups.find((cg) => cg.contact_group === name.value))

const isDisabled = () => {
  return name.value.length === 0 || items.value.length === 0 || items.value.filter((e) => e.status === false).length > 0 || checkName.value
}

const ConfirmAddContacts = () => {
  const is = items.value.map((mailbox) => mailbox.item)

  AddContactGroup.mutate({ name: name.value, mailboxes: is.filter((item, index) => is.indexOf(item) === index) }, {
    onSuccess: () => {
      name.value = ''
      const query = queryClient.getQueryCache().find(['contactGroups'], { exact: true })
      query?.fetch()
      hideModal()
      if (!import.meta.env.VITE_DEMO.length) {
        toast.success({
          component: ToastComponent,
          props: {
            title: 'New contact group created',
            messenges: 'This change will be reflected in your dashboard tomorrow at 00:01 local time'
          },
          options: { icon: CheckCircleIcon }
        }, {
          timeout: 10000
        })
      } else {
        toast.success(ToastDemoComponent, { icon: CheckCircleIcon })
      }
    }
  })
}
</script>

<template>
  <p class="text-gray-900 font-semibold leading-8 text-lg p-6 border-b">New contact group</p>
  <div class="p-6 border-b overflow-auto rounded-lg">
    <div class="pb-8">
      <label for="contact" class="block mb-1 text-sm font-medium leading-5 text-gray-700">Group name</label>
      <input
        id="contact"
        type="text"
        class="block w-full rounded-md border-0 py-2 px-3.5 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-5.25"
        placeholder="e.g Golden Clients"
        v-model="name"
      />
      <span v-show="checkName" class="text-red-400 block mt-1 text-left text-xs font-inter font-medium">
        Please check that the name is not repeated
      </span>
    </div>

    <div>
      <label for="inputAddEmail" class="block mb-1 text-sm font-medium leading-5 text-gray-700">Email addresses or domains</label>
      <InputMultiple
        placeholder="Type in or paste a list of mailboxes and/or domains"
        maxHeight="h-32"
        v-model:text="item"
        v-model:items="items"

        :reg="(e:string) => (!(domainReg.test(e) || subdomainReg.test(e) || emailReg.test(e)))"
        :allowedDomains="[]"
        :onlyExistEmails="[]"
      />
    </div>
  </div>
  <ButtonsModal :isDisabled="isDisabled()" @submit="ConfirmAddContacts" />
</template>

<style scope></style>
