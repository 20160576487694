<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { Listbox } from '@headlessui/vue'
import { useToast } from 'vue-toastification'
import { useQueryClient } from '@tanstack/vue-query'

import type { EditBusinessData } from '@/modules/shared/interfaces/Modal'
import type { IBhs } from '@/modules/settings/interfaces/BusinessHours'

import useModal from '@/modules/shared/composable/useModal'
import ButtonsModal from '@/modules/shared/components/ButtonsModal.vue'
import selectSimple from '@/modules/shared/components/SelectSimple'
import businessHours from '@/modules/settings/components/businessHours'
import { useTableStore } from '@/modules/settings/stores/table'
import useBusinessHours from '@/modules/settings/composables/useBusinessHours'
import ToastComponent from '@/modules/shared/components/ToastComponent.vue'
import { CheckCircleIcon } from '@heroicons/vue/solid'
import ToastDemoComponent from '@/modules/shared/components/ToastDemoComponent.vue'

const { editMailboxBusinessData, hideModal } = useModal()
const toast = useToast()
const queryClient = useQueryClient()
const { setSelectedItems } = useTableStore()

const { createBusinessHours, deleteBusinessHours } = useBusinessHours([editMailboxBusinessData.value[0].email])

const business = [
  {
    title: 'Company',
    description: 'This mailbox will use the Business Hours set at the company level.'
  },
  {
    title: 'Custom',
    description: 'Use a custom set of Business Hours for this mailbox.'
  },
  {
    title: 'Ignored',
    description: 'Business Hours won’t apply to this mailbox.'
  }
]

const selected = ref((():{ title: string, description: string } => {
  if (editMailboxBusinessData.value.length === 1) {
    if (editMailboxBusinessData.value[0].bhsStatus === 'Company') return business[0]
    else if (editMailboxBusinessData.value[0].bhsStatus === 'Custom') return business[1]
    else return business[2]
  } else {
    return business[0]
  }
})())

const editBusinessData = ref<IBhs>({
  timezone: editMailboxBusinessData.value[0].bhs.timezone,
  businessHours: editMailboxBusinessData.value[0].bhs.businessHours
})

watch(editMailboxBusinessData.value, (newBhs) => {
  if (newBhs[0].bhsStatus === 'Custom' && newBhs.length === 1) {
    editBusinessData.value.timezone = newBhs[0].bhs.timezone
    editBusinessData.value.businessHours = newBhs[0].bhs.businessHours
  }
})

const save = () => {
  const data: EditBusinessData = {
    businessHours: selected.value?.title === 'Custom' ? editBusinessData.value.businessHours : [],
    mailboxes: [...editMailboxBusinessData.value.map((mailbox) => mailbox.email)],
    timezone: selected.value?.title === 'Custom' ? editBusinessData.value.timezone : '',
    type: selected.value?.title
  }

  if (['Custom', 'Ignored'].includes(data.type)) {
    const newBusinessHours = {
      mailboxes: data.mailboxes,
      timezone: data.type === 'Custom' ? editBusinessData.value.timezone : 'Europe/Madrid',
      business_hours: data.type === 'Custom'
        ? editBusinessData.value.businessHours.map((bsh) => ({ weekday: bsh.id.slice(0, 3), start_time: `${bsh.start_time}:00`, end_time: `${bsh.end_time}:00` }))
        : [
            { weekday: 'Mon', start_time: '00:00:00', end_time: '23:59:59' },
            { weekday: 'Tue', start_time: '00:00:00', end_time: '23:59:59' },
            { weekday: 'Wed', start_time: '00:00:00', end_time: '23:59:59' },
            { weekday: 'Thu', start_time: '00:00:00', end_time: '23:59:59' },
            { weekday: 'Fri', start_time: '00:00:00', end_time: '23:59:59' },
            { weekday: 'Sat', start_time: '00:00:00', end_time: '23:59:59' },
            { weekday: 'Sun', start_time: '00:00:00', end_time: '23:59:59' }
          ]
    }

    createBusinessHours.mutate(newBusinessHours, {
      onSuccess: () => {
        setSelectedItems([])
        const query = queryClient.getQueryCache().find(['mailboxes'], { exact: true })
        query?.fetch()
        hideModal()
        if (!import.meta.env.VITE_DEMO.length) {
          toast.success({
            component: ToastComponent,
            props: {
              title: 'Business hours have been updated',
              messenges: 'This change will be reflected in your dashboard tomorrow at 00:01 local time'
            },
            options: { icon: CheckCircleIcon }
          }, {
            timeout: 10000
          })
        } else {
          toast.success(ToastDemoComponent, { icon: CheckCircleIcon })
        }
      }
    })
  } else if (data.type === 'Company') {
    deleteBusinessHours.mutate(data.mailboxes!, {
      onSuccess: () => {
        setSelectedItems([])
        const query = queryClient.getQueryCache().find(['mailboxes'], { exact: true })
        query?.fetch()
        hideModal()
        if (!import.meta.env.VITE_DEMO.length) {
          toast.success({
            component: ToastComponent,
            props: {
              title: 'Business hours have been updated',
              messenges: 'This change will be reflected in your dashboard tomorrow at 00:01 local time'
            },
            options: { icon: CheckCircleIcon }
          }, {
            timeout: 10000
          })
        } else {
          toast.success(ToastDemoComponent, { icon: CheckCircleIcon })
        }
      }
    })
  }
}

const inactive = computed(() => {
  if (selected.value.title === 'Custom') { return (!(editBusinessData.value.businessHours.length) || ['', 'UTC', null].includes(editBusinessData.value.timezone)) }
  return false
})
</script>

<template>
  <p class="text-gray-900 font-semibold leading-8 text-lg p-6 border-b">Edit Business Hours</p>
    <div class="p-6 border-b overflow-auto">
      <p class="text-sm text-gray-500 mb-5">Each mailbox can have its own Business Hours settings. This is useful for global organizations with different schedules per department or location.</p>
      <Listbox as="div">
        <div class="relative mt-1">
          <selectSimple :options="business" v-model:values="selected" />
        </div>
      </Listbox>
      <div v-if="selected.title === 'Custom'" class="pt-6">
        <businessHours v-model:days="editBusinessData.businessHours" v-model:timezone="editBusinessData.timezone" :data="editBusinessData" />
      </div>
    </div>

    <ButtonsModal
      type="Continue"
      @submit="save"
      :isDisabled="inactive"
    />
</template>

<style scoped></style>
