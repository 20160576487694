<script setup lang="ts">
import type { PropType } from 'vue'

import { DotsHorizontalIcon } from '@heroicons/vue/solid'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import ButtonR from '@/modules/shared/components/ButtonRemove.vue'
import useModal from '@/modules/shared/composable/useModal'
import MODALS from '@/modules/shared/utils/modals_enum'
import type { ITeams } from '@/modules/settings/interfaces/Teams'
import useTable from '@/modules/settings/composables/useTable'
import useTeams from '@/modules/settings/composables/useTeams'
import { setDates } from '@/modules/settings/utils/helpers/dates'

const { selectedItems, selectItem } = useTable()
const { showModalUpdateTeam, removeModal } = useModal()
const { removeData } = useTeams()

defineProps({
  teams: {
    type: Object as PropType<ITeams[]>,
    required: true
  }
})

</script>

<template>
  <tbody class="divide-y divide-gray-200">
    <tr v-for="team in teams" :key="team.team" :class="[selectedItems.includes(team.team) && 'bg-gray-50']" class="h-18">
      <td class="relative px-7 sm:w-12 sm:px-6">
        <input
          type="checkbox"
          class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-500 focus:ring-0 focus:ring-offset-0"
          :checked="selectedItems.includes(team.team)"
          @change="selectItem($event, team.team)"
        />
      </td>
      <td class="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900">
        <div class="truncate w-96">{{ team.team }}</div>
      </td>
      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {{ team.mailboxes.length }} mailboxes
      </td>
      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {{ setDates(team.created) }}
      </td>
      <td class="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-3 text-center">
        <Menu as="div" class="relative inline-block text-left">
          <div>
            <MenuButton class="headlessui-menu-button flex items-center rounded p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:ring-0 focus:ring-offset-0">
              <span class="sr-only">Open options</span>
              <DotsHorizontalIcon class="h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="absolute py-1 right-0 z-10 w-[164px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div>
                <MenuItem >
                  <ButtonR
                    type-color="gray"
                    text="Edit team"
                    @click="showModalUpdateTeam({ name: MODALS.updateTeams, data:{ departament: team.team, mailboxes: team.mailboxes }})"
                  />
                </MenuItem>
                <MenuItem >
                  <ButtonR type-color="red" text="Remove team" @click="removeModal(removeData([team.team]))" />
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </td>
    </tr>
  </tbody>
</template>

<style scoped></style>
