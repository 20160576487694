import { watch } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { storeToRefs } from 'pinia'
import type { IUserInfo } from '@/modules/shared/interfaces/UserInfo'
import { useUserInfoStore } from '@/modules/shared/stores/userInfo'

const useUserInfo = () => {
  const store = useUserInfoStore()
  const { userInfo } = storeToRefs(store)

  const { isLoading, data } = useQuery(['userInfo'], async ():Promise<IUserInfo> => {
    return {
      allowedDomains: ['dundermifflin.com'],
      maxMailboxes: 25,
      maxUsers: 25,
      maxAlerts: 5,
      email: 'michael.scott@dundermifflin.com',
      isAdmin: 'admin',
      name: 'Michael Scott',
      roles: 'admin'
    }
  }, { staleTime: 10 * (60 * 1000) })

  watch(data, newUserInfo => {
    if (newUserInfo) store.setUserInfo(newUserInfo)
  }, { immediate: true })

  return {
    userInfo,
    isLoading
  }
}

export default useUserInfo
