import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useMutation, useQuery } from '@tanstack/vue-query'

import { useMailboxesStore } from '@/modules/settings/stores/mailboxes'
import MODALS from '@/modules/shared/utils/modals_enum'
import type { IMailboxes, IcreateMailboxes, IdeleteMailboxes } from '@/modules/settings/interfaces/Mailboxes'

const useMailboxes = () => {
  const storeMailboxes = useMailboxesStore()
  const { mailboxes } = storeToRefs(storeMailboxes)

  const getUseMailboxes = useQuery(['mailboxes'], async ():Promise<IMailboxes[]> => {
    if (mailboxes.value[0].email === '') {
      storeMailboxes.setMailboxes([{
        bhs: {
          hover: false,
          timezone: '',
          businessHours: []
        },
        bhsStatus: 'Company',
        email: 'michael.scott@dundermifflin.com',
        name: 'Michael Scott',
        dataAdded: '2023-07-18T12:47:59.827622Z',
        dataFrom: '2021-07-18T00:00:00Z',
        status: 'Active',
        teams: ''
      }, {
        bhs: {
          hover: false,
          timezone: '',
          businessHours: []
        },
        bhsStatus: 'Company',
        email: 'pam.beesly@dundermifflin.com',
        name: 'Pam Beesly',
        dataAdded: '2023-07-18T12:47:59.827622Z',
        dataFrom: '2021-07-18T00:00:00Z',
        status: 'Active',
        teams: ''
      }, {
        bhs: {
          hover: false,
          timezone: '',
          businessHours: []
        },
        bhsStatus: 'Company',
        email: 'jim.halpert@dundermifflin.com',
        name: 'Jim Halpert',
        dataAdded: '2023-07-18T12:47:59.827622Z',
        dataFrom: '2021-07-18T00:00:00Z',
        status: 'Active',
        teams: ''
      }, {
        bhs: {
          hover: false,
          timezone: '',
          businessHours: []
        },
        bhsStatus: 'Company',
        email: 'meredith.palmer@dundermifflin.com',
        name: 'Meredith Palmer',
        dataAdded: '2023-07-18T12:47:59.827622Z',
        dataFrom: '2021-07-18T00:00:00Z',
        status: 'Active',
        teams: ''
      }, {
        bhs: {
          hover: false,
          timezone: '',
          businessHours: []
        },
        bhsStatus: 'Company',
        email: 'dwight.schrute@dundermifflin.com',
        name: 'Dwight Schrute',
        dataAdded: '2023-07-18T12:47:59.827622Z',
        dataFrom: '2021-07-18T00:00:00Z',
        status: 'Active',
        teams: ''
      }, {
        bhs: {
          hover: false,
          timezone: '',
          businessHours: []
        },
        bhsStatus: 'Company',
        email: 'angela.martin@dundermifflin.com',
        name: 'Angela Martin',
        dataAdded: '2023-07-18T12:47:59.827622Z',
        dataFrom: '2021-07-18T00:00:00Z',
        status: 'Active',
        teams: ''
      }, {
        bhs: {
          hover: false,
          timezone: '',
          businessHours: []
        },
        bhsStatus: 'Company',
        email: 'stanley.hudson@dundermifflin.com',
        name: 'Stanley Hudson',
        dataAdded: '2023-07-18T12:47:59.827622Z',
        dataFrom: '2021-07-18T00:00:00Z',
        status: 'Active',
        teams: ''
      }, {
        bhs: {
          hover: false,
          timezone: '',
          businessHours: []
        },
        bhsStatus: 'Company',
        email: 'kelly.kapoor@dundermifflin.com',
        name: 'Kelly Kapoor',
        dataAdded: '2023-07-18T12:47:59.827622Z',
        dataFrom: '2021-07-18T00:00:00Z',
        status: 'Active',
        teams: ''
      }, {
        bhs: {
          hover: false,
          timezone: '',
          businessHours: []
        },
        bhsStatus: 'Company',
        email: 'kevin.malone@dundermifflin.com',
        name: 'Kevin Malone',
        dataAdded: '2023-07-18T12:47:59.827622Z',
        dataFrom: '2021-07-18T00:00:00Z',
        status: 'Active',
        teams: ''
      }, {
        bhs: {
          hover: false,
          timezone: '',
          businessHours: []
        },
        bhsStatus: 'Company',
        email: 'phyllis.vance@dundermifflin.com',
        name: 'Phyllis Vance',
        dataAdded: '2023-07-18T12:47:59.827622Z',
        dataFrom: '2021-07-18T00:00:00Z',
        status: 'Active',
        teams: ''
      }, {
        bhs: {
          hover: false,
          timezone: '',
          businessHours: []
        },
        bhsStatus: 'Company',
        email: 'sales@dundermifflin.com',
        name: 'Dunder Mifflin Sales',
        dataAdded: '2023-07-18T12:47:59.827622Z',
        dataFrom: '2021-07-18T00:00:00Z',
        status: 'Active',
        teams: ''
      }, {
        bhs: {
          hover: false,
          timezone: '',
          businessHours: []
        },
        bhsStatus: 'Company',
        email: 'hr@dundermifflin.com',
        name: 'Dunder Mifflin HR',
        dataAdded: '2023-07-18T12:47:59.827622Z',
        dataFrom: '2021-07-18T00:00:00Z',
        status: 'Active',
        teams: ''
      }, {
        bhs: {
          hover: false,
          timezone: '',
          businessHours: []
        },
        bhsStatus: 'Company',
        email: 'finance@dundermifflin.com',
        name: 'Dunder Mifflin Finance',
        dataAdded: '2023-07-18T12:47:59.827622Z',
        dataFrom: '2021-07-18T00:00:00Z',
        status: 'Active',
        teams: ''
      }
      ])
    }
    return await new Promise<IMailboxes[]>((resolve) => {
      resolve([...mailboxes.value])
    })
  })

  const createMailboxesMutation = useMutation(async (ms: IcreateMailboxes):Promise<void> => {
    const a = ms.emails.map((e:string) => {
      const fullName = /^([^]+)@(\w+).(\w+)$/.exec(e) || ['']
      return {
        bhs: {
          hover: false,
          timezone: '',
          businessHours: []
        },
        bhsStatus: 'Company',
        email: e,
        dataAdded: ms.data_from,
        dataFrom: ms.data_from,
        teams: '',
        status: 'Active',
        name: fullName[1]
      }
    })

    storeMailboxes.setMailboxes([
      ...mailboxes.value,
      ...a
    ])
  })

  const deleteDemoMailboxes = async (mailboxesDelete: IdeleteMailboxes) => {
    mailboxesDelete.data.emails.forEach((email) => {
      const indexForDelete = mailboxes.value.findIndex(
        (mailbox) => mailbox.email === email
      )
      const arrayMailboxes = [...mailboxes.value]
      arrayMailboxes.splice(indexForDelete, 1)
      storeMailboxes.setMailboxes([...arrayMailboxes])
    })
  }
  const deleteMailboxesMutation = useMutation(async (mailboxes: IdeleteMailboxes):Promise<void> => deleteDemoMailboxes(mailboxes))

  return {
    // properties
    mailboxes,

    // Method
    createMailboxes: createMailboxesMutation,
    deleteMailboxes: deleteMailboxesMutation,
    removeData: (mailboxes:string[]) => ({
      name: MODALS.removeModal,
      data: {
        type: 'mailboxes',
        title: `Are you sure you want to remove ${mailboxes.length > 1 ? 'these mailboxes' : 'this mailbox'}?`,
        text: `We’ll stop collecting email statistics for ${mailboxes.join(', ')}.`,
        data: mailboxes
      }
    }),
    // computed
    isErrorGet: computed(() => getUseMailboxes.isError.value),
    onlyExistEmails: computed(() => [...mailboxes.value.map(m => m.email)])
  }
}

export default useMailboxes
