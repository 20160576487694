import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { IMetricAdjustmentsFront } from '@/modules/settings/interfaces/MetricAdjustments'
import type { Iitems } from '@/modules/shared/interfaces/inputMultiple'

export const useMetricAdjustmentsStore = defineStore('excludeEmails', () => {
  const metricAdjustments = ref<{id: number, select1: string, select2: string, text:string, items: Iitems[], space: boolean}[]>([])
  const excludeEmails = ref<IMetricAdjustmentsFront[]>([])
  const internalDomains = ref<Iitems[]>([])
  const whitelisted = ref<string[]>([])

  return {
    // properties
    metricAdjustments,
    excludeEmails,
    internalDomains,
    whitelisted,

    // Actions
    setBusinessHours (newMetricAdjustments: {id: number, select1: string, select2: string, text:string, items: Iitems[], space: boolean}[]) { metricAdjustments.value = newMetricAdjustments },
    setExcludeEmails (newExcludeEmails: IMetricAdjustmentsFront[]) { excludeEmails.value = newExcludeEmails },
    setInternalDomains (newInternalDomains: Iitems[]) { internalDomains.value = newInternalDomains },
    setWhitelisted (newWhitelisted: string[]) { whitelisted.value = newWhitelisted }
  }
})
