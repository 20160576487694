<script setup lang="ts">
import { onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import ModalComponent from '@/modules/shared/components/ModalComponent.vue'
import clientsApi from './api/clients-api'
import { ME } from './api/url'
// import useDemoApi from './modules/shared/composable/useDemoApi'

const router = useRouter()

declare global {
  interface Window {
    dataLayer: {
      email?: string,
      full_name?: string,
      user_role?: string,
      client_name?: string,
      email_provider?: string,
      origin: string
      event: string
    }[];
  }
}

onBeforeMount(() => {
  const url = new URL(window.location.href)
  const savedToken = localStorage.getItem('userToken') || ''
  const urlToken = url.searchParams.get('token') || ''
  window.dataLayer = window.dataLayer || []

  if (savedToken.length > 0 || urlToken.length > 0) {
    localStorage.setItem('userToken', urlToken.length > 0 ? urlToken : savedToken)
    initGTM()
    if (urlToken !== '') router.push({ name: 'dashboard' })
  } else {
    window.dataLayer.push({
      origin: 'enterprise_portal',
      event: 'datalayer_ready'
    })
    localStorage.setItem('errorLogin', url.searchParams.get('error') || '')
    router.push({ name: 'login' })
  }
})

const initGTM = async () => {
  if (!import.meta.env.VITE_BACKEND_HOST) {
    // const me2 = await useDemoApi().getMe()
    const { data: me } = await clientsApi.get(ME)

    window.dataLayer.push({
      email: me.email,
      full_name: me.full_name,
      user_role: me.role,
      client_name: me.client_data.client_name,
      email_provider: me.client_data.provider,
      origin: 'enterprise_portal',
      event: 'datalayer_ready'
    })
  }
}

</script>

<template>
  <ModalComponent />
  <RouterView />
</template>

<style scoped></style>
