import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useBusinessHoursStore } from '@/modules/settings/stores/businessHours'
import { useMutation, useQuery } from '@tanstack/vue-query'
import type { IBhs, IIBhs } from '@/modules/settings/interfaces/BusinessHours'
import { weekdaysFormat } from '@/modules/settings/utils/parser/bhs'

const useCompanyBusinessHours = () => {
  const businessHoursStore = useBusinessHoursStore()
  const { businessHours } = storeToRefs(businessHoursStore)

  const getCompanyBusinessHoursMutation = useQuery(['companyBusinessHours'], async ():Promise<IBhs> => {
    if (!businessHours.value.businessHours.length && businessHours.value.timezone === '') {
      const data = {
        timezone: 'America/New York',
        businessHours: [
          { id: 'Monday', name: 'M', status: true, start_time: '09:00', end_time: '17:00' },
          { id: 'Tuesday', name: 'T', status: true, start_time: '09:00', end_time: '17:00' },
          { id: 'Wednesday', name: 'W', status: true, start_time: '09:00', end_time: '17:00' },
          { id: 'Thursday', name: 'T', status: true, start_time: '09:00', end_time: '17:00' },
          { id: 'Friday', name: 'F', status: true, start_time: '09:00', end_time: '17:00' }
        ]
      }
      businessHoursStore.setBusinessHours(data)
      return data
    } else {
      businessHoursStore.setBusinessHours(businessHours.value)
      return businessHours.value
    }
  })

  watch(getCompanyBusinessHoursMutation.data, bh => {
    if (bh) businessHoursStore.setBusinessHours(bh)
  }, { immediate: true })

  const createCompanyBusinessHoursMutation = useMutation(async (businessHours: IIBhs):Promise<void> => {
    businessHoursStore.setBusinessHours(weekdaysFormat(businessHours))
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteCompanyBusinessHoursMutation = useMutation(async (data: string):Promise<void> => {
    businessHoursStore.setBusinessHours({
      timezone: 'UTC',
      businessHours: []
    })
  })

  return {
    // properties
    businessHours,

    // Method
    createCompanyBusinessHours: createCompanyBusinessHoursMutation,
    deleteCompanyBusinessHours: deleteCompanyBusinessHoursMutation
  }
}

export default useCompanyBusinessHours
