import type { Iitems } from '@/modules/shared/interfaces/inputMultiple'
import type { IMetricAdjustmentsBack, IMetricAdjustmentsFront } from '@/modules/settings/interfaces/MetricAdjustments'

export interface IMetricAdjustments {
  id: number;
  select1: string;
  select2: string;
  text:string;
  items: Iitems[];
  space: boolean;
}

export const excludedFiltersBack = (items:IMetricAdjustments[]) => {
  const metricAdjustments:IMetricAdjustmentsBack = {
    labels: { is: [], contains: [] },
    domains: { is: [], contains: [] },
    folders: { is: [], contains: [] },
    subject: { is: [], contains: [] },
    addresses: { is: [], contains: [] },
    categories: { is: [], contains: [] }
  }

  items.forEach((item) => {
    switch (item.select1) {
      case 'Address':
        if (item.select2 === 'is') metricAdjustments.addresses.is = [...metricAdjustments.addresses.is, ...item.items.map((i) => i.item)]
        else if (item.select2 === 'contains') metricAdjustments.addresses.contains = [...metricAdjustments.addresses.contains, ...item.items.map((i) => i.item)]
        break
      case 'Domain':
        if (item.select2 === 'is') metricAdjustments.domains.is = [...metricAdjustments.domains.is, ...item.items.map((i) => i.item)]
        else if (item.select2 === 'contains') metricAdjustments.domains.contains = [...metricAdjustments.domains.contains, ...item.items.map((i) => i.item)]
        break

      case 'Subject':
        if (item.select2 === 'is') metricAdjustments.subject.is = [...metricAdjustments.subject.is, ...item.items.map((i) => i.item)]
        else if (item.select2 === 'contains') metricAdjustments.subject.contains = [...metricAdjustments.subject.contains, ...item.items.map((i) => i.item)]
        break

      case 'Label':
        if (item.select2 === 'is') metricAdjustments.labels.is = [...metricAdjustments.labels.is, ...item.items.map((i) => i.item)]
        else if (item.select2 === 'contains') metricAdjustments.labels.contains = [...metricAdjustments.labels.contains, ...item.items.map((i) => i.item)]
        break
      default:
        break
    }
  })
  return metricAdjustments
}

export const internalDomainsFront = (domains: string[]) => domains.map((domain) => ({ item: domain, status: true }))

export const excludedFiltersFront = (items: IMetricAdjustmentsBack) => {
  let c = 1
  const res:IMetricAdjustmentsFront[] = []
  if (items.addresses.is.length > 0) res.push({ id: c++, select1: 'Address', select2: 'is', text: '', items: internalDomainsFront(items.addresses.is), space: false })
  if (items.addresses.contains.length > 0) res.push({ id: c++, select1: 'Address', select2: 'contains', text: '', items: internalDomainsFront(items.addresses.contains), space: false })

  if (items.domains.is.length > 0) res.push({ id: c++, select1: 'Domain', select2: 'is', text: '', items: internalDomainsFront(items.domains.is), space: false })
  if (items.domains.contains.length > 0) res.push({ id: c++, select1: 'Domain', select2: 'contains', text: '', items: internalDomainsFront(items.domains.contains), space: false })

  if (items.subject.is.length > 0) res.push({ id: c++, select1: 'Subject', select2: 'is', text: '', items: internalDomainsFront(items.subject.is), space: false })
  if (items.subject.contains.length > 0) res.push({ id: c++, select1: 'Subject', select2: 'contains', text: '', items: internalDomainsFront(items.subject.contains), space: false })

  if (items.labels.is.length > 0) res.push({ id: c++, select1: 'Label', select2: 'is', text: '', items: internalDomainsFront(items.labels.is), space: false })
  if (items.labels.contains.length > 0) res.push({ id: c++, select1: 'Label', select2: 'contains', text: '', items: internalDomainsFront(items.labels.contains), space: false })

  return res
}
