import { computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useMutation, useQuery } from '@tanstack/vue-query'

import { useUsersStore } from '@/modules/settings/stores/users'
import MODALS from '@/modules/shared/utils/modals_enum'
import type { IUsers, IcreateUsers, IdeleteUsers } from '@/modules/settings/interfaces/Users'
import useUserInfo from '@/modules/shared/composable/useUserInfo'

interface IRolesMapping {
  'admin': string;
  'manager': string;
  'viewer': string;
}

const useUsers = () => {
  const storeUsers = useUsersStore()
  const { users } = storeToRefs(storeUsers)
  const { userInfo } = useUserInfo()

  const getUseUsers = useQuery(['users'], async ():Promise<IUsers[]> => {
    if (users.value.length === 0) {
      storeUsers.setUsers([
        {
          dataFrom: [],
          email: userInfo.value.email,
          role: 'Admin',
          tracking: 'false',
          name: userInfo.value.name
        }, {
          email: 'toby.flenderson@dundermifflin.com',
          name: 'Toby Flenderson',
          role: 'Admin',
          dataFrom: [],
          tracking: 'true'
        }, {
          email: 'jim.halpert@dundermifflin.com',
          name: 'Jim Halpert',
          role: 'Manager',
          dataFrom: [
            'jim.halpert@dundermifflin.com',
            'sales@dundermifflin.com'
          ],
          tracking: 'true'
        }, {
          email: 'dwight.schrute@dundermifflin.com',
          name: 'Dwight Schrute',
          role: 'Viewer',
          dataFrom: [],
          tracking: 'true'
        }
      ])
    }
    return await new Promise<IUsers[]>((resolve) => {
      resolve(users.value)
    })
  })
  watch(getUseUsers.data, users => {
    if (users) storeUsers.setUsers(users)
  }, { immediate: true })

  const usersCreateMutation = useMutation(async (usersData: IcreateUsers):Promise<void> => {
    deleteDemoUsers({ data: usersData })

    const ROLES_MAPPING: IRolesMapping = {
      admin: 'Admin',
      manager: 'Manager',
      viewer: 'Viewer'
    }

    const usersArray = usersData.emails.map((e:string) => {
      const fullName = /^([^]+)@(\w+).(\w+)$/.exec(e) || ['']
      return {
        dataFrom: usersData.role === 'viewer' ? [] : usersData.manage_mailboxes,
        email: e,
        role: ROLES_MAPPING[usersData.role as keyof IRolesMapping],
        tracking: 'false',
        name: fullName[1]
      }
    })

    storeUsers.setUsers([
      ...users.value,
      ...usersArray
    ])
  })

  const deleteDemoUsers = async (usersData: IdeleteUsers) => {
    usersData.data.emails.forEach((email) => {
      const indexForDelete = users.value.findIndex(
        (user) => user.email === email
      )
      if (indexForDelete !== -1) {
        const arrayUsers = [...users.value]
        arrayUsers.splice(indexForDelete, 1)
        storeUsers.setUsers([...arrayUsers])
      }
    })
  }

  const usersDeleteMutation = useMutation(async (usersData: IdeleteUsers):Promise<void> => deleteDemoUsers(usersData))

  return {
    users,
    // Method
    createUsers: usersCreateMutation,
    deleteUsers: usersDeleteMutation,
    removeData: (users:string[]) => ({
      name: MODALS.removeModal,
      data: {
        type: 'users',
        title: `Are you sure you want to remove ${users.length > 1 ? 'these users' : 'this user'}?`,
        text: `${users.join(', ')} will lose access to see email statistics.`,
        data: users
      }
    }),

    // computed
    isErrorGet: computed(() => getUseUsers.isError.value),
    onlyExistEmails: computed(() => [...users.value.map(m => m.email)])
  }
}

export default useUsers
