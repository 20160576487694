/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref, type ExtractPropTypes, type PropType } from 'vue'

export interface PropsSelectMultiple {
  options: {
    type: PropType<string[]>;
    required: true
  };
  values: {
    type: PropType<string[]>;
    required: true
  };
}

interface Emits {
  (e: 'update:values', values2: string[]): void;
}

const useSelectMultiple = (props: Readonly<ExtractPropTypes<PropsSelectMultiple>>, emits:Emits) => {
  const open = ref(false)
  const search = ref('')

  const boxMiltiselect = ref()
  const multiselectContent = ref()
  const multiselectOptions = ref()

  const calcWindow = () => {
    setTimeout(() => {
      const bottom = boxMiltiselect.value.getBoundingClientRect().bottom
      const height = boxMiltiselect.value.getBoundingClientRect().height
      if (height > 64) boxMiltiselect.value.children[1].style.paddingRight = '24px'
      else boxMiltiselect.value.children[1].style.paddingRight = '12px'
      multiselectOptions.value.style.top = `${bottom + 5}px`
    }, 100)
  }

  const optionsFilter = () => {
    if (!search.value) return props.options
    return props.options.filter((v:string) => v.includes(search.value))
  }

  const optionSelected = (option:string) => (props.values.includes(option))

  const selected = (option:string) => {
    if (props.values.includes(option)) emits('update:values', [...props.values.filter((v:string) => v !== option)])
    else emits('update:values', [...props.values, option])
    calcWindow()
  }

  const selectedAll = () => {
    emits('update:values', [...props.values, ...optionsFilter().filter((v) => !props.values.includes(v))])
    calcWindow()
  }

  const selectedNone = () => {
    emits('update:values', [...props.values.filter((v) => !optionsFilter().includes(v))])
    calcWindow()
  }

  const DeleteItem = (option:string) => {
    emits('update:values', props.values.filter((v:string) => v !== option))
    calcWindow()
  }

  const openSelect = () => {
    const bottom = boxMiltiselect.value.getBoundingClientRect().bottom
    const left = boxMiltiselect.value.getBoundingClientRect().left
    const width = boxMiltiselect.value.getBoundingClientRect().width
    const maxHeight = window.innerHeight - (bottom + 5 + 70 + 20)

    multiselectOptions.value.style.top = `${bottom + 5}px`
    multiselectOptions.value.style.left = `${left}px`
    multiselectOptions.value.style.width = `${width}px`

    if (maxHeight <= 200) {
      multiselectContent.value.style.maxHeight = `${maxHeight - 10}px`
    } else {
      multiselectContent.value.style.maxHeight = '200px'
    }

    open.value = !open.value
  }

  const closeDropdown = (e: { srcElement: any }): void => {
    const ids = []
    let element = e.srcElement

    while (element !== null) {
      ids.push(element.id)
      if (element.offsetParent) element = element.offsetParent
      else element = null
    }
    if (!ids.includes('SelectMultipleOptions')) open.value = false
  }

  return {
    open,
    search,
    multiselectContent,
    boxMiltiselect,
    multiselectOptions,

    calcWindow,
    optionsFilter,
    optionSelected,
    selected,
    selectedAll,
    selectedNone,
    DeleteItem,
    openSelect,
    closeDropdown
  }
}

export default useSelectMultiple
