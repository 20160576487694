/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'

const clientsApi = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_HOST,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const requestHandler = (request:any) => {
  request.headers.Authorization = `Token ${localStorage.getItem('userToken')}` || ''
  return request
}

const responseHandler = (response:any) => {
  if (response.status === 401) {
    window.location.href = '/login'
  }
  return response
}

const errorHandler = (error:any) => {
  if (error.response.status === 401) {
    window.location.href = '/login'
  }
  return Promise.reject(error)
}

clientsApi.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
)

clientsApi.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
)

export default clientsApi
