import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { ICreateAlerts } from '@/modules/explore/interfaces/Alerts'

export const useAlertsStore = defineStore('alerts', () => {
  const alerts = ref<ICreateAlerts[]>([])

  return {
    alerts,

    setAlerts (newAlerts: ICreateAlerts[]) {
      alerts.value = [...newAlerts]
    }
  }
})
